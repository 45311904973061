import { IStepSyncProgressJSON, StepSyncProgress, IStepSyncProgressOptions } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { SyncProgress } from 'o365.pwa.modules.client.SyncProgress.ts';
import { type SyncDefinition } from 'o365.pwa.modules.client.steps.SyncDefinition.ts';
import type { SyncType, TruncateIndexDBObjectStoreMode } from 'o365.pwa.types.ts';

export interface IStepDefinitionOptions {
    stepId: string;
    title?: string;
    dependOnPreviousStep?: Array<string>;
    vueComponentName?: string;
    vueComponentImportCallback?: () => Promise<any>;
    subVueComponentsDefinitions?: Array<{ vueComponentName: string; vueComponentImportCallback: () => Promise<any>; }>;
}

export interface IStepDefinitionPrivateOptions {
    vueComponentName: string;
    vueComponentImportCallback: () => Promise<any>;
    subVueComponentsDefinitions?: Array<{ vueComponentName: string; vueComponentImportCallback: () => Promise<any>; }>;
}

export abstract class StepDefinition {
    public readonly stepId: string;
    public title?: string;
    public readonly dependOnPreviousStep: Array<string>;
    public readonly vueComponentName: string;
    public readonly vueComponentImportCallback: () => Promise<any>;
    public readonly subVueComponentsDefinitions?: Array<{ vueComponentName: string; vueComponentImportCallback: () => Promise<any>; }>;

    constructor(options: IStepDefinitionOptions & IStepDefinitionPrivateOptions) {
        this.stepId = options.stepId;
        this.title = options.title;
        this.dependOnPreviousStep = options.dependOnPreviousStep ?? new Array<string>();
        this.vueComponentName = options.vueComponentName;
        this.vueComponentImportCallback = options.vueComponentImportCallback;
        this.subVueComponentsDefinitions = options.subVueComponentsDefinitions;
    }

    abstract generateStepProgress(options?: IStepSyncProgressOptions | IStepSyncProgressJSON, syncType?: SyncType): StepSyncProgress;

    abstract toRunStepDefinition(): StepDefinition;
}

export interface ISyncOptions<T extends StepSyncProgress> {
    syncProgress: SyncProgress;
    stepProgress: T;
    memory: Object;
    syncRunDefinition: SyncDefinition
    currentIndex: number;
    dependencyMapping: Map<string, number>;
    getPwaVueAppInstance: Function;
}

export interface IOfflineStepDefinition<T extends StepSyncProgress> {
    IOfflineStepDefinition: 'IOfflineStepDefinition';

    syncOffline(options: ISyncOptions<T>): Promise<void>;
}

export function isIOfflineStepDefinition(obj: any): obj is IOfflineStepDefinition<StepSyncProgress> {
    return 'IOfflineStepDefinition' in obj && obj.IOfflineStepDefinition === 'IOfflineStepDefinition';
}

export interface IOnlineStepDefinition<T extends StepSyncProgress> {
    IOnlineStepDefinition: 'IOnlineStepDefinition';

    syncOnline(options: ISyncOptions<T>): Promise<void>;
}

export function isIOnlineStepDefinition(obj: any): obj is IOnlineStepDefinition<StepSyncProgress> {
    return 'IOnlineStepDefinition' in obj && obj.IOnlineStepDefinition === 'IOnlineStepDefinition';
}


export interface IStepTruncateIndexedDB<T extends StepSyncProgress> {
    IStepTruncateIndexedDB: 'IStepTruncateIndexedDB';

    truncateMode?: TruncateIndexDBObjectStoreMode;

    truncateData(options: ISyncOptions<T>): Promise<void>;
}

export function isIStepTruncateIndexedDB(obj: any): obj is IStepTruncateIndexedDB<StepSyncProgress> {
    return 'IStepTruncateIndexedDB' in obj && obj.IStepTruncateIndexedDB === 'IStepTruncateIndexedDB';
}
